import React from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import { useLanguage, translations } from './LanguageContext';

const ImpressumView = ({ onClose }) => {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
    >
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
        className="bg-white text-black p-6 sm:p-8 rounded-lg max-w-2xl max-h-[80vh] overflow-y-auto w-full sm:w-auto"
        style={{ borderRadius: '0' }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl sm:text-2xl font-light tracking-wider">{t.impressum}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="text-gray-600 font-light leading-relaxed text-sm sm:text-base">
          <h3 className="text-lg sm:text-xl mb-2">{t.contactInformation}</h3>
          <p>Alexander Warth</p>
          <p></p>
          <p>Darmstadt</p>
          <p>Germany</p>


          <h3 className="text-lg sm:text-xl mt-4 mb-2">{t.legalInformation}</h3>
          <p>VAT ID: TBD</p>
          <p>Commercial Register: TBD</p>
          <p>Register Court: TBD</p>

          <h3 className="text-lg sm:text-xl mt-4 mb-2">{t.responsibleForContent}</h3>
          <p>Alexander Warth</p>

          <p className="mt-4">{t.impressumCompliance}</p>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ImpressumView;